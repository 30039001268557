.preloader__content {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}
.preloader__content div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 7px solid transparent;
    border-radius: 50%;
    animation: preloader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #001b38 transparent transparent transparent;
}
.preloader__content div:nth-child(1) {
    animation-delay: -0.45s;
}
.preloader__content div:nth-child(2) {
    animation-delay: -0.3s;
}
.preloader__content div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes preloader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.preloader {
    width: 100vw;
    height: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
}

.preloader .preloader__content  {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    height: 250px;
}
.preloader .preloader__content  div {
    border-width: 10px;
}

.preloader__content {
    display: flex;
    justify-content: center;
    align-items: center;
}
.preloader__content img {
    max-height: 200px;
    /* transition: opacity 1s;
    animation: blink 1.7s infinite ease-in-out; */
}
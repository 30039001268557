body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
}

#root {
    width: 100%;
}

.App {
    transition: all 0.5s;
    overflow-x: hidden;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

main {
    opacity: 0;
    transition: opacity 0.6s;
    animation: appear 0.6s forwards;
}

* {
    padding: 0;
    margin: 0;
    outline: 0;
    border: 0;
    box-sizing: border-box;
    font-family: 'Montserrat';
    color: #001b38;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: white;
}

a {
    text-decoration: none;
    transition: all 0.2s;
}

button {
    cursor: pointer;
    color: #000000;
    background-color: #FFCD5F;
    padding: 13px;
    font-weight: 500;
    transition: all 0.2s;
}

button font {
    color: #ffffff !important;
}

.btn_dark {
    color: #ffffff;
    background-color: #001b38;
}

.btn_dark:hover {
    background-color: #2e3341;
}

button:hover {
    background-color: #ffb81f;
}

.phone-link:hover {
    color: #9f9e9e;
}

h1 {
    font-size: 48px;
    line-height: 1.23;

}

h2 {
    font-size: 36px;
    text-transform: uppercase;

}

h3 {
    font-weight: 500;
    font-size: 32px;

}

@media screen and (max-width: 600px) {
    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 24px;
    }
}

input:not([type="file"]):not([type="checkbox"]),
textarea {
    width: 100%;
    height: 50px;
    color: rgb(0, 0, 0);
    /* border: 1px solid rgb(0, 0, 0);
    background-color: rgb(255, 255, 255); */
    font-size: 16px;
    font-weight: 400;
    padding-left: 10px;
    resize: vertical;
    border: 1px solid rgba(0, 0, 0, 0.7);
}

textarea {
    padding: 20px;
    padding-left: 10px;
    display: block;
    height: 100px;
}

.market-underline {
    position: absolute;
    width: 100%;
    height: 5px;
    background-color: #ffb81f;
    bottom: -20px;
}

@font-face {
    font-family: 'Montserrat';
    src: url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: 'Montserrat';
    src: url(./assets/fonts/Montserrat/Montserrat-Medium.ttf) format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: url(./assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: 'Montserrat';
    src: url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: 'Montserrat';
    src: url(./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf) format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: 'Montserrat';
    src: url(./assets/fonts/Montserrat/Montserrat-Black.ttf) format("opentype");
    font-weight: 900;
}

.flag-container .country-list {
    right: 0;
}

.element_hidden {
    opacity: 0;
}

.element_shown {
    opacity: 1;
}

@media screen and (max-width: 600px) {

    input:not([type="file"]),
    textarea {
        /* width: 270px !important; */
    }

    h1 {
        font-size: 34px;
    }

    h2 {
        font-size: 26px;
    }

    h3 {
        font-size: 18px;
    }

    button {
        font-size: 12px;
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.ui__marked-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ui__marked-title>span {
    display: inline-block;
    width: 7px;
    height: 20px;
    background-color: grey;
    opacity: 0.3;
    margin-right: 7px;
}

.underline {
    width: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: -12px;
    opacity: 0.6;
}

ol {
    list-style-position: inside;
}

ol li p {
    display: inline;
}

ol li button {
    display: block;
}

.marked-text {
    background-color: #f4e058;
}

select {
    border: 1px solid #001b38;
    width: 200px;
}
.popup-background {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    opacity: 0;
    transition: opacity 0.7s;
    animation: appear 0.3s forwards;
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.popup-blur {
    background: rgba(0, 0, 0, 0.7);
}
/* .popup-scroll > div {
    overflow: auto;
} */